import * as React from "react"
import { Seo } from "../components/seo"
import Layout from '../components/layout'
import Sidebar from '../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const PartnerFamilyPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Partner Family</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
				<iframe title="Partner Family Form" src="https://docs.google.com/forms/d/e/1FAIpQLScboQGpyzn2vH0aytg0x4spGS3LbJ5bgj9R67DLfV5SCk1cWw/viewform?embedded=true" width="640" height="2900" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default PartnerFamilyPage

export const Head = () => (
	<Seo
        title="Partner Family | Habitat For Humanity Northwoods Wisconsin"
    />
)
